// src/redux/authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isVerified: false,
    isAdmin: false,
    userId: null,
    userData: null, // Add userData to the state
    token: null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setVerificationStatus: (state, action) => {
            state.isVerified = action.payload;
        },
        setAdminStatus: (state, action) => {
            state.isAdmin = action.payload;
        },
        setUserData: (state, action) => {
            state.userData = action.payload; // Add a reducer to set userData
        },
        setUserId: (state, action) => {
            state.userId = action.payload; // Add a reducer to set userId
        },
        setToken: (state, action) => {
            state.token = action.payload; // Add a reducer to set userId
        },
        clearUserData: () => {
            return initialState;
        }
    },
});

export const { setVerificationStatus, setAdminStatus, setUserData, setUserId, setToken, clearUserData } = authSlice.actions;

export default authSlice.reducer;
