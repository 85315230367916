const basemapStyles = [
    {
      label: 'Street',
      value: 'mapbox://styles/mapbox/light-v11',
      translationKey: 'app.street',  // Key for translation
    },
    {
      label: 'Dark Mode',
      value: 'mapbox://styles/mapbox/dark-v10',
      translationKey: 'app.darkMode',  // Key for translation
    },
    // Add more basemap styles here as needed
  ];
  
  export default basemapStyles;
  