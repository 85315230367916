import React, { useState } from 'react';
import { Box, Grid, IconButton, Tooltip, styled } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ProjectForm from '../components/ProjectForm';
import MapComponent from '../components/MapComponent';
import ErrorBoundary from '../components/ErrorBoundary';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const StyledProjectFormContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '2px',
    height: '2px',
    transition: 'opacity 0.3s',
    opacity: 0,
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'transparent',
    borderRadius: '4px',
  },
  '&:hover': {
    '&::-webkit-scrollbar': {
      opacity: 1,
      background: theme.palette.action.disabledBackground,
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#000',
    },
  },
}));

const ProjectPage = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const iconColor = theme.palette.mode === 'dark' ? '#ffffff' : 'inherit';

  return (
    <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
      <Grid container sx={{ flex: 1 }}>
        {/* ProjectForm - only rendered if not collapsed */}
        {!collapsed && (
          <StyledProjectFormContainer item xs={12} md={3}>
            <ErrorBoundary>
              <ProjectForm collapsed={collapsed} setCollapsed={setCollapsed} />
            </ErrorBoundary>
          </StyledProjectFormContainer>
        )}

        {/* Map Component Area */}
        <Grid item xs={12} md={collapsed ? 12 : 9} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Box sx={{ flex: 1, p: 0 }}>
            <ErrorBoundary>
              <MapComponent
                center={[139.6917, 35.6895]}
                zoom={12}
                highResolution={true}
                drawTools={true}
                overlay={false}
              />
            </ErrorBoundary>
          </Box>
        </Grid>
      </Grid>

      {/* Floating Button to Open ProjectForm when collapsed */}
      {collapsed && (
        <Tooltip title={t('app.expandPanel')} arrow>
          <IconButton
            onClick={() => setCollapsed(false)}
            sx={{
              position: 'fixed',
              left: 0,
              top: '15%',
              transform: 'translateY(-50%)',
              backgroundColor: 'background.paper',
              color: iconColor,
              zIndex: 1300,
              boxShadow: 3,
              borderRadius: '0 8px 8px 0',
              '&:hover': {
                backgroundColor: theme.palette.mode === 'dark' ? '#000000' : 'white',
              },
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default ProjectPage;
