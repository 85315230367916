// src/redux/projectSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentProjectId: null,
    currentProjectData: {},
    allProjectIds: [],
    isModeling: false,
    currentModelingProjectId: null,
    shapeStatus: null, // Add shapeStatus to track shape changes
    projectStatus: null,
};

export const projectSlice = createSlice({
    name: 'project',
    initialState: initialState,
    reducers: {
        setCurrentProjectId: (state, action) => {
            state.currentProjectId = action.payload;
        },
        setCurrentProjectData: (state, action) => {
            state.currentProjectData = action.payload;
        },
        setAllProjectIds: (state, action) => {
            state.allProjectIds = action.payload;
        },
        setIsModeling: (state, action) => {
            state.isModeling = action.payload;
        },
        setCurrentModelingProjectId: (state, action) => {
            state.currentModelingProjectId = action.payload;
        },
        setShapeStatus: (state, action) => {
            state.shapeStatus = action.payload; // Update shape status (e.g., "created" or "deleted")
        },
        clearProjects: () => {
            return initialState;
        },
        setProjectStatus: (state, action) => {
          state.projectStatus = action.payload;
      },
    },
});

export const {
    setCurrentProjectId,
    setCurrentProjectData,
    setAllProjectIds,
    setIsModeling,
    setCurrentModelingProjectId,
    setShapeStatus,
    clearProjects,
    setProjectStatus,
} = projectSlice.actions;

export default projectSlice.reducer;
