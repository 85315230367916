/* eslint-disable react-hooks/exhaustive-deps */
// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ThemeContextProvider } from './ThemeContext';
import globalStyles from './GlobalStyles';
import routes from './routes';
import { BasemapProvider } from './BaseMapProvider';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { getUser } from './api/user';
import { Hub } from "aws-amplify/utils";
import { getCurrentUser, fetchAuthSession } from "aws-amplify/auth";
import UnauthorizedAccess from './components/UnAuthorizedAccess';
import { useDispatch, useSelector } from 'react-redux';
import { setVerificationStatus, setAdminStatus, setUserData ,setUserId, setToken} from './redux/authSlice'; // Import setUserData action
import { I18n } from "aws-amplify/utils";
import { translations } from "@aws-amplify/ui-react";
import "./App.css";

I18n.putVocabularies(translations);

function App() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const [isVerified, setIsVerified] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState("ja");

  useEffect(() => {
    I18n.putVocabularies({
      en: {
        "Preferred Username": "Preferred Username",
        "Enter your Preferred Username": "Enter your Preferred Username",
      },
      ja: {
        "Preferred Username": "優先ユーザ名",
        "Enter your Preferred Username": "お好みのユーザー名を入力してください",
      },
    });

    I18n.setLanguage(currentLanguage);
  }, [currentLanguage]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const langParam = params.get("lang");
    if (langParam && (langParam === "en" || langParam === "ja")) {
      setCurrentLanguage(langParam);
    }
  }, []);



  const checkUserVerification = async () => {
    try {
       
        const user = await getCurrentUser();
        const userData = await getUser(user?.username);
        
        const isVerified = userData.data.UserAttributes.find(attr => attr.Name === "custom:isVerified")?.Value === "true";
        const isAdmin = userData.data.UserAttributes.find(attr => attr.Name === "custom:isAdmin")?.Value === "true";

        setIsVerified(isVerified);

        const session = await fetchAuthSession(); // Fetch the authentication session
        
        dispatch(setToken(session?.tokens?.accessToken?.toString()));

        dispatch(setVerificationStatus(isVerified));
        dispatch(setAdminStatus(isAdmin));
        dispatch(setUserId(userData?.data?.Username));
        dispatch(setUserData(userData.data.UserAttributes)); // Dispatch userData to Redux store
    } catch (error) {
        console.error("Failed to fetch user details", error);
        dispatch(setVerificationStatus(false));
        dispatch(setAdminStatus(false));
    } finally {
        setLoading(false);
    }
};


  useEffect(() => {
    const authListener = ({ payload }) => {
      switch (payload.event) {
        case "signedIn":
          checkUserVerification();
          break;
        case "signedOut":
          // Handle sign out if needed
          break;
        default:
          break;
      }
    };

    Hub.listen("auth", authListener);
    checkUserVerification();

    return () => {
      // Cleanup Hub listener if needed
    };
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>; // Or a spinner, etc.
  }

  return (
    <ThemeContextProvider>
      <BasemapProvider>
        <CssBaseline />
        {globalStyles}
        <Authenticator
          initialState="signIn"
          loginMechanisms={["email"]}
          signUpAttributes={["email", "preferred_username"]}
        >
          {({ signOut, user }) => (
            <Router>
              <Routes>
                {user && isVerified ? (
                  routes(isAdmin).map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      element={route.element}
                    />
                  ))
                ) : (
                  [
                    <Route key="unauth" path="/" element={<UnauthorizedAccess />} />,
                  ]
                )}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Router>
          )}
        </Authenticator>
      </BasemapProvider>
    </ThemeContextProvider>
  );
}

export default App;
