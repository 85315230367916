import React from 'react';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';
import { useAuthenticator } from '@aws-amplify/ui-react';

function UnauthorizedAccess() {
  const { signOut } = useAuthenticator();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: (theme) => theme.palette.background.default,
        p: 2,
      }}
    >
      <Card
        sx={{
          width: { xs: '90%', sm: '70%', md: '50%' },
          boxShadow: 8,
          borderRadius: 4,
          p: 4,
          textAlign: 'center',
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
      >
        <CardContent>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: 'text.primary' }}>
            Access Denied
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: 'text.secondary' }}>
            You are not authorized to view this page. Please wait for admin approval or contact support if you think this is an error.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={signOut}
            sx={{
              mt: 3,
              borderRadius: 20,
              px: 4,
              py: 1.5,
              textTransform: 'uppercase',
              fontWeight: 'bold',
              boxShadow: 6,
              '&:hover': {
                boxShadow: 8,
              },
            }}
          >
            Sign Out
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
}

export default UnauthorizedAccess;
