import axiosAPI from "./axiosInstance";

const fetchUsers = async (token) => {
    try {
      const response = await axiosAPI.get('/users', {
        headers: {
          Authorization: `Bearer ${token}` // Include the token in the Authorization header
        }
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  };

  const updateUserAttributes = async (username, attributes, token) => {
    try {
        const response = await axiosAPI.put(`/user/${username}`, attributes, {
          headers: {
            Authorization: `Bearer ${token}` // Include the token in the Authorization header
          }
        });
        return response.data;
      } catch (error) {
        console.error("Error updating user:", error);
        throw error;
      }
  };

  const deleteUser = async (username, token) => {
    try {
        const response = await axiosAPI.delete(`/user/${username}`, {
          headers: {
            Authorization: `Bearer ${token}` // Include the token in the Authorization header
          }
        });
        return response.data;
      } catch (error) {
        console.error("Error updating user:", error);
        throw error;
      }
  };

  const getUser = async (username) => {
    try {
        const response = await axiosAPI.get(`/user/${username}`);
        return response;
      } catch (error) {
        console.error("Error getting user:", error);
        throw error;
      }
  };
export {
    fetchUsers,updateUserAttributes,deleteUser,getUser
}