import React, { createContext, useContext, useEffect, useState } from 'react';

const BasemapContext = createContext();
export const BasemapProvider = ({ children }) => {
  const [basemap, setBasemap] = useState(localStorage.getItem("basemap") || 'mapbox://styles/mapbox/light-v11');

  useEffect(() => {
    localStorage.setItem("basemap", basemap);
  }, [basemap]);
  
  return (
    <BasemapContext.Provider value={{ basemap, setBasemap }}>
      {children}
    </BasemapContext.Provider>
  );
};

export const useBasemap = () => useContext(BasemapContext);
