
import React from 'react';
import ReactDOM from 'react-dom/client';  // Updated import for React 18
import App from './App';
import './i18n'; // Import i18n configuration
import {Amplify } from 'aws-amplify';
import awsconfig from './awsconfig';
import { Provider } from 'react-redux';
import { store } from './redux/store';
Amplify.configure(awsconfig);
// Use createRoot to manage the root container in React 18
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the App component inside the root
root.render(
  <React.StrictMode>
     <Provider store={store}>
    <App />
    </Provider>
  </React.StrictMode>
);
