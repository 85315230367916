// routes.js
import ProjectsListPage from './pages/ProjectsListPage';
import ProjectPage from './pages/ProjectPage';
import ReportPage from './pages/ReportPage';
import MainLayout from './layouts/MainLayout';
import Dashboard from './components/Dashboard';

const routes = (isAdmin) => [
  {
    path: '/',
    element: <MainLayout><ProjectsListPage /></MainLayout>
  },
  {
    path: '/project/:projectId',
    element: <MainLayout><ProjectPage /></MainLayout>
  },
  {
    path: '/project',
    element: <MainLayout><ProjectPage /></MainLayout>
  },
  {
    
    path: '/projectreport/:projectId',
    element: <MainLayout><ReportPage /></MainLayout>
  },
  ...(isAdmin ? [
    {
      path: '/dashboard',
      element: <MainLayout><Dashboard /></MainLayout>
    }
  ] : []),
  // Add more routes here
];

export default routes;
