import i18n from 'i18next';  // Ensure i18next is correctly initialized in your project

class LegendControl {
  constructor(polygonOptions, t) {
    this.polygonOptions = polygonOptions;
    this.t = t;
    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    this._container.style.backgroundColor = '#fff';
    this._container.style.color = '#000'; // Ensure text color is black for readability
    this._container.style.borderRadius = '3px';
    this._container.style.boxShadow = '0 1px 2px rgba(0, 0, 0, 0.1)';
    this._container.style.padding = '10px';
    this._container.style.fontFamily = 'Arial, sans-serif';
    this._container.style.overflow = 'auto';
    this._container.style.margin = '10px';
  }

  onAdd(map) {
    this._map = map;
    this.initializeLegend();
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }

  initializeLegend() {
    const heading = document.createElement('div');
    heading.textContent = this.t("app.landCoverType"); // Use a key for internationalization
    heading.style.fontWeight = 'bold';
    heading.style.marginBottom = '10px';
    this._container.appendChild(heading);

    this.polygonOptions.forEach(option => {
      const color = this.getTypeColor(option.value);
      const item = document.createElement('div');
      item.style.display = 'flex';
      item.style.alignItems = 'center';
      item.style.marginBottom = '5px';

      const key = document.createElement('span');
      key.style.display = 'inline-block';
      key.style.height = '10px';
      key.style.width = '20px';
      key.style.backgroundColor = color;
      key.style.marginRight = '10px';

      // Select label or title based on the current language
      const text = i18n.language === 'ja' ? option.title : option.label;
      const label = document.createTextNode(text);
      item.appendChild(key);
      item.appendChild(label);
      this._container.appendChild(item);
    });
  }

  getTypeColor(type) {
    switch(type) {
      case 'completeScopeOfWork':
        return 'blue';
      case 'extentOfLandAlteration':
        return 'green';
      default:
        return '#ccc'; // Default color for unspecified types
    }
  }
}

export default LegendControl;
