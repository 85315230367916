/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import mapboxgl from "mapbox-gl";
import { useDispatch } from "react-redux";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import { useLocation, useParams } from "react-router-dom";

import LegendControl from "./LegendControl";
import { polygonOptions } from "../resources/projectFormOptions";
import { CircularProgress, Box, Snackbar, Alert } from "@mui/material";
import {
  getShapesByProjectId,
  createShape,
  updateShape,
  deleteShape,
  getBufferringById,
  getBufferringByUser,
  getResultsById,
  getProjectById
} from "../api/project";
import LandcoverPopup from "./LandcoverPopup";
import { getColorByType } from "../helpers/functions";
import Overlay from "./Overlay";
import { useBasemap } from "../BaseMapProvider";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { lineColors } from "../resources/mapLayerConfig";
import { setCurrentProjectId, setShapeStatus } from "../redux/projectSlice"; // Adjust the path as necessary
import { io } from "socket.io-client";

// Inside MapComponent

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const MapComponent = ({
  center = [139.6917, 35.6895],
  zoom = 12,
  highResolution = false,
  drawTools = true,
  overlay = false,
  main = false,
  collapsed = false
}) => {
  const { basemap } = useBasemap();
  const mapContainer = useRef(null);
  const mapInstance = useRef(null);
  const dispatch = useDispatch();
  const legendContainer = useRef(null);
  const selectedProjectId = useSelector(
    (state) => state.project.currentProjectId
  );
  const projectStatus = useSelector((state) => state.project.projectStatus);  
  const [bufferingData, setBufferingData] = useState([]);
  const [shizendoMax, setShizendoMax] = useState(10); // Default shizendo max
  const [sliderValue, setSliderValue] = useState([1, shizendoMax]);
  const drawInstance = useRef(null);
  const { projectId } = useParams();
  const token = useSelector((state) => state.auth.token);
  const [newShape, setNewShape] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const isDarkMode = localStorage.getItem('theme') === 'dark';

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [overlayOpen, setOverlayOpen] = useState(true);
  const allProjectIds = useSelector((state) => state.project.allProjectIds);
  const currentProjectId = useSelector(
    (state) => state.project.currentProjectId
  );
  const language = new MapboxLanguage({
    defaultLanguage: "ja",
  });

  const [projectStat, setProjectStat] = useState({});
  const isModeling = useSelector(state => state.project.isModeling);
  const currentModelingProjectId = useSelector(state => state.project.currentModelingProjectId);
  const location = useLocation();
  const [triggerReload, setTriggerReload] = useState(false);
  useEffect(() => {
    if (isModeling && currentModelingProjectId) {
      setProjectStat((prevStatus) => ({
        ...prevStatus,
        [currentModelingProjectId]: 'running'
      }));
    }
  }, [isModeling, currentModelingProjectId]);

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_UE_SOCKET_PORT}`);
    
    // Set initial status if `isModeling` and `currentModelingProjectId` are available
    if (isModeling && currentModelingProjectId) {
      setProjectStat((prevStatus) => ({
        ...prevStatus,
        [currentModelingProjectId]: 'running'
      }));
    }
  
    // Listen for job status updates
    socket.on('jobStatus', (data) => {
      setProjectStat((prev) => {
        const updatedStatus = { ...prev, [data.projectId]: data.status };
        
        // Check if the status for the current project is "success" and location is "/"
        if (data.status === 'success' && location.pathname === '/') {
          setTriggerReload(true);
        }
        
        return updatedStatus;
      });
    });
  
    // Cleanup on unmount
    return () => {
      socket.off('jobStatus');
      socket.disconnect();
    };
  }, [isModeling, currentModelingProjectId, location.pathname]);  
  
  const handlePopupClose = () => {
    setPopupOpen(false);
    if (newShape && !newShape.properties.id) {
      drawInstance.current.delete([newShape.id]);
      setNewShape(null);
    }
  };

  const panToProjectResults = (currentProjectId) => {

    if (mapInstance.current) {
      const source = mapInstance.current.getSource("result-ring");
      if (source && source._data && source._data.features) {
        // Find the feature that matches the current project ID
        const feature = source._data.features.find(
          (f) => f.properties.project_id === currentProjectId
        );

        if (feature && feature.geometry) {
          const { coordinates } = feature.geometry;

          // Ensure we handle both Polygon and MultiPolygon types
          const polygons =
            feature.geometry.type === "Polygon" ? [coordinates] : coordinates;

          // Create bounds and extend for all polygon coordinates
          const bounds = new mapboxgl.LngLatBounds();
          polygons.forEach((polygon) => {
            polygon.forEach((ring) => {
              ring.forEach(([lng, lat]) => bounds.extend([lng, lat]));
            });
          });

          if (!bounds.isEmpty()) {
            mapInstance.current.fitBounds(bounds, {
              padding: 20,
              maxZoom: 10,
              duration: 2000,
            });
          } else {
            // Pan to default if bounds are empty
            mapInstance.current.flyTo({
              center: center,
              zoom: zoom,
            });
          }
        } else {
          // No valid feature found, pan to default
          mapInstance.current.flyTo({
            center: center,
            zoom: zoom,
          });
          if (!feature) showSnackbar(t("app.noModelingMessage"));
        }
      } else {
        // No source or features found, pan to default
        mapInstance.current.flyTo({
          center: center,
          zoom: zoom,
        });
      }
    }
  };

  // For tracking the slider value
  // Updated slider change handler to reflect the range filter
const handleSliderChange = (event, newValue) => {
  setSliderValue(newValue);
  filterPolygonsByShizendo(newValue);
};


  // Filter polygons based on shizendo value
  // Filter polygons based on shizendo value (range-based filter)
  const filterPolygonsByShizendo = (range) => {
    if (mapInstance.current) {
      if (range[0] === 1 && range[1] === shizendoMax) {
        mapInstance.current.setFilter("results-fill", null); // Remove all filters if range is default
      } else {
        mapInstance.current.setFilter("results-fill", [
          "all",
          [">=", ["get", "shizendo"], range[0]], // Filter for polygons with shizendo >= min
          ["<=", ["get", "shizendo"], range[1]], // Filter for polygons with shizendo <= max
        ]);
      }
    }
  };

  // Clear filters function
  const handleClearFilters = () => {
    setSliderValue([1,shizendoMax]); // Reset the slider to 0
    filterPolygonsByShizendo([1,shizendoMax]); // Remove all filters
  };

  const toggleOverlay = () => {
    setOverlayOpen((prev) => !prev);
  };

  const addShapesToMap = async (mapInstance, projectId, center, zoom, lineWidth = 1) => {
    if (!mapInstance) {
      console.error("Map instance is undefined.");
      return;
  }
  if (!projectId) {
      console.error("Project ID is undefined.");
      return;
  }
  
    try {
      const shapes = await getShapesByProjectId(projectId, token);      
  
      const features = shapes.map((shape) => ({
        type: "Feature",
        properties: {
          id: shape.shape_id,
          landcover_type: shape.landcover_type,
        },
        geometry: shape.geom,
        id: shape.shape_id,
      }));
  
      // Set features in draw instance if it exists
      if (drawInstance.current) {
        drawInstance.current.set({
          type: "FeatureCollection",
          features: features,
        });
      }
  
      // Add the source to the map if it doesn't already exist
      if (!mapInstance.getSource("shapes")) {
        mapInstance.addSource("shapes", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: features,
          },
        });
      } else {
        // Update the existing source
        mapInstance.getSource("shapes").setData({
          type: "FeatureCollection",
          features: features,
        });
      }
  
      // Add the line and fill layers if they don't exist
      if (!mapInstance.getLayer("shapes")) {
        mapInstance.addLayer({
          id: "shapes",
          type: "line",
          source: "shapes",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": [
              "match",
              ["get", "landcover_type"],
              ...getColorByType("red"),
              "red",
            ],
            "line-width": lineWidth,
          },
        });
      }
  
      if (!mapInstance.getLayer("shapes-fill")) {
        mapInstance.addLayer({
          id: "shapes-fill",
          type: "fill",
          source: "shapes",
          layout: {},
          paint: {
            "fill-color": [
              "match",
              ["get", "landcover_type"],
              ...getColorByType("#ff69b4"),
              "#ff69b4",
            ],
            "fill-opacity": 0.01,
          },
        });
      }
  
      // Pan and zoom to fit the polygons
      if(!overlay){
        if (features.length > 0) {
          const bounds = new mapboxgl.LngLatBounds();
          features.forEach((feature) => {
            const { geometry } = feature;
            if (geometry.type === "Polygon") {
              geometry.coordinates.forEach((ring) => {
                ring.forEach(([lng, lat]) => bounds.extend([lng, lat]));
              });
            } else if (geometry.type === "MultiPolygon") {
              geometry.coordinates.forEach((polygon) => {
                polygon.forEach((ring) => {
                  ring.forEach(([lng, lat]) => bounds.extend([lng, lat]));
                });
              });
            }
          });
    
          if (bounds.isEmpty()) {
            mapInstance.setCenter(center);
            mapInstance.setZoom(zoom);
          } else {
            mapInstance.fitBounds(bounds, {
              padding: 50,
              maxZoom: 15,
              duration: 1000,
            });
          }
        }
      }
    } catch (error) {
      console.error("Error fetching and adding shapes to map:", error);
    }
  };

  useEffect(() => {
    if (projectStatus === 'success') {
        fetchBufferingData(); // Call your function that needs to run on success
    }
}, [projectStatus]);

  useEffect(() => {
    if (mapInstance.current) return;

    mapInstance.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: basemap,
      center: center,
      zoom: zoom,
      tileSize: highResolution ? 512 : 256,
      minZoom: 5, // Set minimum zoom level to prevent zooming out too far
    });

    mapInstance.current.addControl(language);
    const navControl = new mapboxgl.NavigationControl();
    mapInstance.current.addControl(navControl, "bottom-left");

    mapInstance.current.on("load", async () => {
      if (drawTools && projectId) {
        drawInstance.current = new MapboxDraw({
          displayControlsDefault: false,
          controls: {
            polygon: true,
            trash: true,
          },
          defaultMode: "simple_select",
        });
        mapInstance.current.addControl(drawInstance.current);
      }

      if (drawTools) {
        mapInstance.current.addControl(new LegendControl(polygonOptions, t), 'bottom-right');
      }

      if (projectId && !overlay) {
        if (mapInstance.current) {
          addShapesToMap(mapInstance.current, projectId, center, zoom);
      }
      
       
      }
    });

    mapInstance.current.on("draw.create", (e) => {
      setNewShape(e.features[0]);
      setPopupOpen(true);
    });

    mapInstance.current.on("draw.update", async (e) => {
      setLoading(true);
      const updatedData = {
        geom: e.features[0].geometry,
        landcover_type: e.features[0].properties.landcover_type,
      };
      const shapeId = e.features[0].properties.id;
      if (shapeId) {
        await updateShape(shapeId, updatedData, token);
        const source = mapInstance.current.getSource("shapes");
        if (source) {
          const data = source._data;
          const featureIndex = data.features.findIndex(
            (f) => f.properties.id === shapeId
          );
          if (featureIndex > -1) {
            data.features[featureIndex].geometry = updatedData.geom;
            data.features[featureIndex].properties.landcover_type =
              updatedData.landcover_type;
            source.setData(data);
          }
        }
        setLoading(false);
        showSnackbar(t("app.shapeUpdatedMessage"));
      }
    });

    mapInstance.current.on("draw.delete", async (e) => {
      setLoading(true);
      const shapeId = e.features[0].properties.id;
      if (shapeId) {
        await deleteShape(shapeId, token);
        const source = mapInstance.current.getSource("shapes");
        if (source) {
          const data = source._data;
          const updatedFeatures = data.features.filter(
            (f) => f.properties.id !== shapeId
          );
          source.setData({ ...data, features: updatedFeatures });
        }
        setLoading(false);
        showSnackbar(t("app.shapeDeletedMessage"));
        dispatch(setShapeStatus(true));
      }
    });

    return () => {
      if (mapInstance.current) {
        
        mapInstance.current.remove();
        mapInstance.current = null;
        drawInstance.current = null;
        
      }
    };
  }, [projectId, drawTools, center, zoom, basemap, highResolution]);


  const fetchBufferingData = async () => {
    if (main) {
      try {
        setLoading(true);
        const response = await getBufferringByUser();
        if (response.length > 0) {
          const newData = response.reduce((acc, item) => {
            acc[item.project_id] = item; // Adjust based on actual data structure if necessary
            return acc;
          }, {});
  
          setBufferingData(newData);
          updateMapWithBufferingData(newData); // Update the map with the new data
        } else {
          setBufferingData({});
          console.log('No buffering data available for the given user');
        }
      } catch (error) {
        console.error("Failed to fetch buffering data:", error);
      } finally {
        setLoading(false);
      }
    }
  };
  

  useEffect(() => {
    fetchBufferingData();
  }, [main, basemap, isDarkMode, projectStatus, triggerReload, collapsed]); // Depend on `main` and `allProjectIds`

  const updateMapWithBufferingData = (bufferingData) => {
    if (mapInstance.current && bufferingData) {
      const features = Object.values(bufferingData).map(projectData => {
        return {
          type: "Feature",
          properties: { ...projectData },
          geometry: projectData.geom,
        };
      });
  
      if (features.length) {
        // Ensure layers are removed first before removing the source
        ['result-ring-fill', 'result-ring'].forEach(layerId => {
          if (mapInstance.current.getLayer(layerId)) {
            mapInstance.current.removeLayer(layerId);
          }
        });
  
        // Now remove the source, ensuring no layers are using it
        if (mapInstance.current.getSource("result-ring")) {
          mapInstance.current.removeSource("result-ring");
        }
  
        // Add source for new features
        mapInstance.current.addSource("result-ring", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features,
          },
        });
  
        // Add fill layer
        mapInstance.current.addLayer({
          id: "result-ring-fill",
          type: "fill",
          source: "result-ring",
          paint: {
            "fill-color": lineColors.resultRing, // Ensure lineColors is defined elsewhere
            "fill-opacity": 0.1,
          },
        });
  
        // Add line layer
        mapInstance.current.addLayer({
          id: "result-ring",
          type: "line",
          source: "result-ring",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": lineColors.resultRing, // Ensure lineColors is defined elsewhere
            "line-width": 2,
          },
        });
  
        // Setup popup logic and other map interactions as before
        setupMapInteractions(mapInstance.current);
      } else {
        console.log('No features to add to the map.');
      }
    }
  };
  
  function setupMapInteractions(map) {
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });
  
    map.on("mousemove", "result-ring-fill", (e) => {
      if (e.features.length > 0) {
        const feature = e.features[0];
        map.getCanvas().style.cursor = "pointer";
        popup
          .setLngLat(e.lngLat)
          .setHTML(`<h4>${feature.properties.name}</h4>`) // Assuming 'name' is the property
          .addTo(map);
      }
    });
  
    map.on("mouseleave", "result-ring-fill", () => {
      map.getCanvas().style.cursor = "";
      popup.remove();
    });
  
    map.on("mousemove", "result-ring", (e) => {
      if (e.features.length > 0) {
        const feature = e.features[0];
        map.getCanvas().style.cursor = "pointer";
        popup
          .setLngLat(e.lngLat)
          .setHTML(`<h4>${feature.properties.name}</h4>`) // Assuming 'name' is the property
          .addTo(map);
      }
    });

    mapInstance.current.on("click", "result-ring", (e) => {
      if (e.features.length > 0) {
        const projectId = e.features[0].properties.project_id;
        dispatch(setCurrentProjectId(projectId));
      }
    });

    mapInstance.current.on("click", "result-ring-fill", (e) => {
      if (e.features.length > 0) {
        const projectId = e.features[0].properties.project_id;
        dispatch(setCurrentProjectId(projectId));
      }
    });
  
    map.on("mouseleave", "result-ring", () => {
      map.getCanvas().style.cursor = "";
      popup.remove();
    });
  }
  
  
  
  

  useEffect(() => {
    const fetchResults = async () => {
      if (overlay) {
        try {
          setLoading(true);
          const results = await getResultsById(projectId, token);
          const bufferingData = await getBufferringById(projectId, token);
                    
          setBufferingData(bufferingData)
          updateOutlineLayer(bufferingData);
          setResults(results); // Assuming results are in JSON format already
          const maxShizendo = Math.max(...results.map((r) => r.shizendo));
          setShizendoMax(maxShizendo); // Set max value for slider
          addResultsToMap(results);
          await addShapesToMap(mapInstance.current, projectId, center, zoom, 1.5);
          setLoading(false);
        } catch (err) {
          console.error("Error fetching results:", err);
          setLoading(false);
        }
      }
    };

    const addResultsToMap = (results) => {
      if (mapInstance.current && results && results.length > 1) {
        const features = results
          .map((result) => ({
            type: "Feature",
            properties: {
              shizendo: result.shizendo,
              status_by_code: result.status_by_code,
              project_id: result.project_id,
              shizendo_max: result.shizendo_max,
              extentOfLandAlteration_area: result.extentOfLandAlteration_area,
              completescopeofwork_area: result.completescopeofwork_area,
              dai_n: result.dai_n,
              chu_n: result.chu_n,
              sai_n: result.sai_n,
              hanrei_exp: result.hanrei_exp,
            },
            geometry: result.geom, // Geometry (polygon or multipolygon)
          }))
          .sort((a, b) => b.properties.shizendo - a.properties.shizendo);

        // Remove layers and source if they already exist

        if (mapInstance.current.getLayer("results-fill")) {
          mapInstance.current.removeLayer("results-fill");
        }
        if (mapInstance.current.getSource("results")) {
          mapInstance.current.removeSource("results");
        }

        // Add the source
        mapInstance.current.addSource("results", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: features,
          },
        });

        // Add the combined fill and outline layer (shizendo >= 1)
        mapInstance.current.addLayer({
          id: "results-fill",
          type: "fill",
          source: "results",
          filter: [">=", ["get", "shizendo"], 1],
          paint: {
            "fill-color": lineColors.resultsFill,
            "fill-opacity": 0.5,
            "fill-outline-color": "#000000", // Outline color for polygons
          },
        });

        // Pan to the second polygon (optional)
        const secondResult = results[1];
        const polygon = secondResult.geom.coordinates[0];
        const bounds = new mapboxgl.LngLatBounds();
        polygon.forEach((coord) => bounds.extend(coord));
        mapInstance.current.fitBounds(bounds, { padding: 50, maxZoom: 10.5 });

        // Add cursor change on hover for results-outline

        // Add cursor change on hover for results-fill
        mapInstance.current.on("mousemove", "results-fill", () => {
          mapInstance.current.getCanvas().style.cursor = "pointer"; // Change cursor to pointer
        });

        mapInstance.current.on("mouseleave", "results-fill", () => {
          mapInstance.current.getCanvas().style.cursor = ""; // Revert cursor to default
        });

        // Popup logic for `results-outline`
        const createPopupDescription = (properties, fields) => {
          return fields
            .map((field) => {
              const label = field.label;
              const value = properties[field.key] ?? ""; // Default to empty string if null or undefined
              return `<strong>${label}:</strong> ${value}<br/>`;
            })
            .join(""); // Join all parts into a single string
        };

        // Popup logic for `results-outline`

        // Popup logic for `results-fill`
        mapInstance.current.on("click", "results-fill", (e) => {
          const feature = e.features[0];

          const fillFields = [
            { key: "dai_n", label: t("app.daiN") },
            { key: "chu_n", label: t("app.chuN") },
            { key: "sai_n", label: t("app.saiN") },
            { key: "hanrei_exp", label: t("app.hanreiExp") },
            { key: "shizendo", label: t("app.shizendo") },
          ];

          const description = `
            <div>
              <strong>${t("app.fillInfo")}</strong><br/><br/>
              ${createPopupDescription(feature.properties, fillFields)}
            </div>
          `;

          new mapboxgl.Popup({ closeButton: true, closeOnClick: true })
            .setLngLat(e.lngLat)
            .setHTML(description)
            .addTo(mapInstance.current);
        });
      }

      
    };

    const updateOutlineLayer = (bufferingData) => {
      if (mapInstance.current && bufferingData) {
        const features = bufferingData.map((data) => ({
          type: "Feature",
          properties: { ...data },
          geometry: data.geom,
        }));

        // Remove the existing layer and source if they exist
        if (mapInstance.current.getLayer("results-outline")) {
          mapInstance.current.removeLayer("results-outline");
        }
        if (mapInstance.current.getSource("results-outline")) {
          mapInstance.current.removeSource("results-outline");
        }

        // Add new source and layer
        mapInstance.current.addSource("results-outline", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features,
          },
        });

        mapInstance.current.addLayer({
          id: "results-outline",
          type: "line",
          source: "results-outline",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": lineColors.resultRing, // bright red for visibility
            "line-width": 5, // thicker line for visibility
          },
        });

        // Center and zoom the map around the new features for visibility check
        if (features.length > 0) {
          const bounds = new mapboxgl.LngLatBounds();
          features.forEach((feature) => {
            feature.geometry.coordinates[0].forEach((coord) => {
              bounds.extend(coord);
            });
          });
          mapInstance.current.fitBounds(bounds, { padding: 20 });
        }

        mapInstance.current.on("mousemove", "results-outline", () => {
          mapInstance.current.getCanvas().style.cursor = "pointer"; // Change cursor to pointer
        });

        mapInstance.current.on("mouseleave", "results-outline", () => {
          mapInstance.current.getCanvas().style.cursor = ""; // Revert cursor to default
        });

        // Popup logic for results-outline
        // const createPopupDescription = (properties) => {
        //   // Define the fields you want to display in the popup
        //   const fields = [
        //     { key: "project_id", label: t("app.projectID") },
        //     { key: "shizendo_max", label: t("app.shizendoMax") },
        //     { key: "status_by_code", label: t("app.statusByCode") },
        //     {
        //       key: "extentOfLandAlteration_area",
        //       label: t("app.extentOfLandAlterationArea"),
        //     },
        //     {
        //       key: "completescopeofwork_area",
        //       label: t("app.completeScopeOfWorkArea"),
        //     },
        //   ];

        //   // Create HTML content for the popup
        //   return fields
        //     .map((field) => {
        //       const value = properties[field.key] ?? ''; // Default to "N/A" if the property is undefined
        //       return `<div><strong>${field.label}:</strong> ${value}</div>`;
        //     })
        //     .join("");
        // };

        // mapInstance.current.on("click", "results-outline", (e) => {
        //   // Prevent the event from propagating to avoid triggering other click handlers
        //   e.originalEvent.stopPropagation();

        //   if (e.features.length > 0) {
        //     const feature = e.features[0];
        //     const description = createPopupDescription(feature.properties);

        //     // Create a new popup instance and set its content
        //     new mapboxgl.Popup({ closeButton: true, closeOnClick: true })
        //       .setLngLat(e.lngLat)
        //       .setHTML(description)
        //       .addTo(mapInstance.current);
        //   }
        // });
      }
    };

    fetchResults();
  }, [overlay, projectId, basemap]);

  useEffect(() => {
    if (currentProjectId) {
      panToProjectResults(currentProjectId);
    } else {
      // If no project is selected, remove highlighting
      mapInstance.current.flyTo({
        center: center,
        zoom: zoom,
      });
    }
  }, [currentProjectId]);

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }}
        >
          <CircularProgress sx={{ animation: "spin 1s linear infinite" }} />
        </Box>
      )}
      <div
        ref={mapContainer}
        style={{
          width: "100%",
          height: "100%",
          filter: loading ? "blur(2px)" : "none",
          transition: "filter 0.3s ease",
        }}
      />
      {popupOpen && (
        <Box sx={{ animation: "fadeIn 0.5s ease" }}>
          <LandcoverPopup
            open={popupOpen}
            onClose={handlePopupClose}
            onSave={handlePopupSave}
          />
        </Box>
      )}
      {overlay && (
        <Overlay
          open={overlayOpen}
          onToggle={toggleOverlay}
          shizendo_max={shizendoMax}
          sliderValue={sliderValue}
          onSliderChange={handleSliderChange}
          onClearFilters={handleClearFilters}
          bufferingData={bufferingData}
        />
      )}


      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          sx={{
            width: "100%",
            backgroundColor: "primary.main",
            color: "primary.contrastText",
            "& .MuiAlert-icon": {
              color: "primary.contrastText",
            },
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );

  async function handlePopupSave(landcoverType) {
    setLoading(true);
    if (newShape) {
      const newShapeData = {
        project_id: projectId,
        geom: newShape.geometry,
        landcover_type: landcoverType,
      };
      const savedShape = await createShape(newShapeData, token);
      newShape.properties.id = savedShape.shape_id;
      newShape.properties.landcover_type = landcoverType;
      drawInstance.current.add(newShape);
      const source = mapInstance.current.getSource("shapes");
      if (source) {
        let data = source._data;
        data.features.push(newShape);
        source.setData(data);
      }
      setNewShape(null);
      setPopupOpen(false);
      setLoading(false);
      showSnackbar(t("app.shapeCreateMessage"));
      dispatch(setShapeStatus(true));
    }
  }
};

export default MapComponent;
