import axiosAPI from "./axiosInstance";

// Fetch all projects
const fetchProjects = async (userId, isAdmin,token) => {
  try {
    const response = await axiosAPI.get(`/projects`, {
      params: {
      
        isAdmin
      },
      headers: {
        Authorization: `Bearer ${token}` // Include the token in the Authorization header
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching projects:", error);
    throw error;
  }
};


const getResultsById = async (cartodb_id, token) => {
  try {
    const response = await axiosAPI.get(`/result/${cartodb_id}`, {
      headers: {
        Authorization: `Bearer ${token}` // Include the token in the Authorization header
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching result by ID:", error);
    throw error;
  }
};

const checkResultsById = async (cartodb_id, token) => {
  try {
    const response = await axiosAPI.get(`/checkresult/${cartodb_id}`, {
      headers: {
        Authorization: `Bearer ${token}` // Include the token in the Authorization header
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching result by ID:", error);
    throw error;
  }
};

const initiateModeling = async (project_id, models, token) => {
  try {
    const response = await axiosAPI.post(`/project/batch/${project_id}`, { models }, {
      headers: {
        Authorization: `Bearer ${token}` // Include the token in the Authorization header
      }
    });
    return response;
  } catch (error) {
    console.error("Error Initiating Modeling for project ID:", project_id, error);
    throw error;
  }
};

const getBufferringById = async (cartodb_id, token) => {
  try {
    const response = await axiosAPI.get(`/bufferring/${cartodb_id}`,{
      headers: {
        Authorization: `Bearer ${token}` // Include the token in the Authorization header
      }
    } );
    return response.data;
  } catch (error) {
    console.error("Error fetching ring by ID:", error);
    throw error;
  }
};


const getBufferringByUser = async (cartodb_id, token) => {
  try {
    const response = await axiosAPI.get(`/bufferring`,{
      headers: {
        Authorization: `Bearer ${token}` // Include the token in the Authorization header
      }
    } );
    return response.data;
  } catch (error) {
    console.error("Error fetching ring by ID:", error);
    throw error;
  }
};

// Create a new project
const createProject = async (projectData, token) => {
  try {
    const response = await axiosAPI.post('/project', projectData, {
      headers: {
        Authorization: `Bearer ${token}` // Include the token in the Authorization header
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error creating project:", error);
    throw error;
  }
};

// Update an existing project
const updateProject = async (cartodb_id, projectData, userId, isAdmin, token) => {
  try {
    const response = await axiosAPI.put(`/project/${cartodb_id}`, projectData, {
      params: {
      
        isAdmin
      },
      headers: {
        Authorization: `Bearer ${token}` // Include the token in the Authorization header
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error updating project:", error);
    throw error;
  }
};

// Delete a project
const deleteProject = async (cartodb_id, userId, isAdmin, token) => {
  try {
    const response = await axiosAPI.delete(`/project/${cartodb_id}`, {
      params: {
    
        isAdmin
      },
      headers: {
        Authorization: `Bearer ${token}` // Include the token in the Authorization header
      }
    });
    return response.data; // or return the entire response to handle status/messages in your component
  } catch (error) {
    console.error("Error deleting project:", error);
    throw error;
  }
};

const getProjectById = async (cartodb_id, userId, isAdmin, token) => {
  try {
    const response = await axiosAPI.get(`/project/${cartodb_id}`, {
      params: {
    
        isAdmin
      },
      headers: {
        Authorization: `Bearer ${token}` // Include the token in the Authorization header
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching project by ID:", error);
    throw error;
  }
};

const updateShape = async (shape_id, shapeData, token) => {
  try {
    const response = await axiosAPI.put(`/project/shape/${shape_id}`, shapeData, {
      headers: {
        Authorization: `Bearer ${token}` // Include the token in the Authorization header
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error updating shape:", error);
    throw error;
  }
};

const getShapesByProjectId = async (shape_id, token) => {
  try {
    const response = await axiosAPI.get(`/project/shape/${shape_id}`, {
      headers: {
        Authorization: `Bearer ${token}` // Include the token in the Authorization header
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching project by ID:", error);
    throw error;
  }
};

const getAllShapes = async (token) => {
  try {
    const response = await axiosAPI.get(`/projects/shapes`, {
      headers: {
        Authorization: `Bearer ${token}` // Include the token in the Authorization header
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching shapes:", error);
    throw error;
  }
};

const deleteShape = async (shape_id, token) => {
  try {
    const response = await axiosAPI.delete(`/project/shape/${shape_id}`, {
      headers: {
        Authorization: `Bearer ${token}` // Include the token in the Authorization header
      }
    });
    return response.data; // or return the entire response to handle status/messages in your component
  } catch (error) {
    console.error("Error deleting project:", error);
    throw error;
  }
};

const createShape = async (shapeData, token) => {
  try {
    const response = await axiosAPI.post('/project/shape', shapeData, {
      headers: {
        Authorization: `Bearer ${token}` // Include the token in the Authorization header
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error creating shape:", error);
    throw error;
  }
};

export { fetchProjects, getProjectById, createProject, updateProject, deleteProject, deleteShape, createShape,updateShape,getShapesByProjectId, initiateModeling, getAllShapes, getBufferringById,getResultsById, checkResultsById, getBufferringByUser };
