import { polygonOptions } from "../resources/projectFormOptions";

const getColorByType = (defaultColor) => {
    const colorMap = {
      'completeScopeOfWork': 'blue',
      'extentOfLandAlteration': 'green'
    };
    return polygonOptions.reduce((acc, option) => {
      acc.push(option.value, colorMap[option.value] || defaultColor);
      return acc;
    }, []);
  };

export {
    getColorByType
}