import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import { store } from '../redux/store';
import { clearUserData, setToken } from '../redux/authSlice';
import { clearProjects } from '../redux/projectSlice';

const axiosAPI = axios.create({
    baseURL: `${process.env.REACT_APP_UE_API}`, 
});

axiosAPI.interceptors.response.use((response) => {      
        return response
    }, 
    async (error) => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const session = await fetchAuthSession({ forceRefresh: true });             
                const newAccessToken = session?.tokens?.accessToken?.toString(); 
                if (newAccessToken) {                    
                    store.dispatch(setToken(newAccessToken));
                    originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return axiosAPI(originalRequest);
                } else {
                    throw new Error('Session is empty. Logging out.');
                }    
                
            } catch (authError) {
                store.dispatch(setToken(null));
                store.dispatch(clearUserData());
                store.dispatch(clearProjects());
                localStorage.clear();
                window.location.href = '/';
            }

        }
        return Promise.reject(error);
});

export default axiosAPI;