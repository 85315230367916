/* eslint-disable no-unused-vars */
import React from "react";
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Collapse,
  useTheme,
  Slider,
  Button,
  Tooltip,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { statusOptions, designedByOptions } from "../resources/projectFormOptions";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Overlay = ({
  open,
  onToggle,
  shizendo_max,
  sliderValue,
  onSliderChange,
  onClearFilters,
  bufferingData,
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentProjectData = useSelector(
    (state) => state.project.currentProjectData
  );
  const userData = useSelector((state) => state.auth.userData);
  // const checkForNull = (value) => (value === "NULL" ? "" : value);
  const username = userData.find(
    (attr) => attr.Name === "preferred_username"
  )?.Value;
  const formatArea = (value) => (
    <span>
      {value?.toFixed(1)} km<sup>2</sup>
    </span>
  );
  const handleBackAction = () => {
    navigate(-1);
  };

  const getText = (options, value) => {
    if (value === "NULL" || value === null || typeof value === 'undefined') {
      return ""; // Handle NULL, null, or undefined values
    }
    const opt = options.find(opt => opt.value === value);
    return i18n.language === 'ja' ? opt?.title : opt?.label;
  };
  const getPublicPrivateStatus = (isPublic) => {
    return isPublic ? t("app.public") : t("app.private");
  };
  return (
    <Paper
      sx={{
        position: "absolute",
        top: 85,
        right: 12,
        width: open ? 370 : 40,
        zIndex: 1001,
        p: open ? 2 : 0.5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        transition: "width 0.3s ease, padding 0.3s ease",
        borderRadius: 2,
        boxShadow: 3,
        backgroundColor: open
          ? "background.paper"
          : theme.palette.mode === "light"
          ? "#e0e0e0"
          : "#424242",
        color: open ? theme.palette.text.primary : "common.white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: open ? 1.5 : 0,
        }}
      >
        {open && (
          <Tooltip title={t("app.back")} arrow>
            <IconButton
              onClick={handleBackAction}
              size="small"
              sx={{ color: "inherit" }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        )}
        <IconButton
          size="small"
          onClick={onToggle}
          sx={{
            color: open
              ? "inherit"
              : theme.palette.mode === "light"
              ? "black"
              : "white",
            ml: open ? 0 : "auto",
          }}
        >
          {open ? (
            <Tooltip title={t("app.close")} arrow>
              <ExpandLessIcon />
            </Tooltip>
          ) : (
            <Tooltip title={t("app.open")} arrow>
              <ExpandMoreIcon />
            </Tooltip>
          )}
        </IconButton>
      </Box>
      <Collapse in={open}>
        <Box sx={{ textAlign: "center", mt: 1.5 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 1.5,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold", mr: 1 }}>
              {t("app.shizendoMax")}:
            </Typography>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
            >
              {shizendo_max}
            </Typography>
          </Box>
          <Box sx={{ mt: 1.5 }}>
            <Typography variant="body2" gutterBottom>
              {t("app.filterBy")}
            </Typography>
            <Slider
              value={sliderValue}
              onChange={onSliderChange}
              aria-labelledby="slider"
              min={1}
              max={shizendo_max}
              step={1}
              sx={{ paddingBottom: "4px" }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 0.5,
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {t("app.minimum")}: 1
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {t("app.maximum")}: {shizendo_max}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 1,
                minHeight: "40px",
                paddingTop: sliderValue === 0 ? "8px" : "0px",
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {sliderValue[0] === 1 && sliderValue[1] === shizendo_max
                  ? `${t("app.noFilters")}`
                  : `${t("app.shizendo")}: ${sliderValue[0]} - ${
                      sliderValue[1]
                    }`}
              </Typography>
              {sliderValue[0] !== 1 && (
                <Button
                  variant="text"
                  color="secondary"
                  size="small"
                  onClick={onClearFilters}
                  sx={{
                    textTransform: "none",
                    fontSize: "0.75rem",
                    padding: "4px 8px",
                    ml: 1,
                  }}
                >
                  {t("app.clear")}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={{ mt: 2, mb: 2 }}>
  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
    {t('app.name')} : <span style={{ fontWeight: 'normal' }}>{bufferingData?.[0]?.name || ""}</span>
  </Typography>
  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
    {t('app.username')} : <span style={{ fontWeight: 'normal' }}>{username || ""}</span>
  </Typography>
  
  {/* modelled date */}
  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
    {t("app.modelledDate")}:{" "}
    <span style={{ fontWeight: "normal" }}>
      {bufferingData?.[0]?.date
        ? new Date(bufferingData?.[0]?.date)
            .toISOString()
            .split("T")[0]
            .replace(/-/g, ".")
        : ""}
    </span>
  </Typography>
  {/* --------------- */}

</Box>
<Box sx={{ mt: 2, mb: 2 }}>
<Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
    {t("app.startdate")}:{" "}
    <span style={{ fontWeight: "normal" }}>
      {currentProjectData.start_date
        ? new Date(currentProjectData.start_date)
            .toISOString()
            .split("T")[0]
            .replace(/-/g, ".")
        : ""}
    </span>
  </Typography>
  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
    {t("app.enddate")}:{" "}
    <span style={{ fontWeight: "normal" }}>
      {currentProjectData.end_date
        ? new Date(currentProjectData.end_date)
            .toISOString()
            .split("T")[0]
            .replace(/-/g, ".")
        : ""}
    </span>
  </Typography>
  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
    {t('app.public')}/ {t('app.private')}: <span style={{ fontWeight: 'normal' }}>
            {getPublicPrivateStatus(currentProjectData.public)}
          </span>
  </Typography>
  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            {t('app.designedBy')}: <span style={{ fontWeight: 'normal' }}>
            {getText(designedByOptions, currentProjectData.designed_by)}
          </span>

          </Typography>

</Box>
<Box sx={{ mt: 2, mb: 2 }}>
<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            {t('app.status')}: <span style={{ fontWeight: 'normal' }}>
            {getText(statusOptions, currentProjectData.status)}
          </span>
          </Typography>
  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
    {t("app.statusByCode")}: <span style={{ fontWeight: 'normal' }}>{bufferingData?.[0]?.status_by_code?.toFixed(2) || ""}</span>
  </Typography>
  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
    {t("app.shizendoMax")}: <span style={{ fontWeight: 'normal' }}>{bufferingData?.[0]?.shizendo_max || ""}</span>
  </Typography>
</Box>
<Box sx={{ mt: 2, mb: 2 }}>
  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
    {t("app.completeScopeOfWorkArea")}: <span style={{ fontWeight: 'normal' }}>{bufferingData?.[0]?.completeScopeOfWork_area ? formatArea(bufferingData[0].completeScopeOfWork_area) : ""}</span>
  </Typography>
  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
    {t("app.extentOfLandAlterationArea")}: <span style={{ fontWeight: 'normal' }}>{bufferingData?.[0]?.extentOfLandAlteration_area ? formatArea(bufferingData[0].extentOfLandAlteration_area) : ""}</span>
  </Typography>
</Box>

      </Collapse>
    </Paper>
  );
};

export default Overlay;
