export const lineColors = {
    resultsFill: [
      "match",
      ["get", "shizendo"],
      1, "#F6FDBE",
      2, "#AAE597",
      3, "#92DA7C",
      4, "#92DA7C",
      5, "#5EB2A0",
      6, "#399B8C",
      7, "#00718B",
      8, "#00718B",
      9, "#01365A",
      10, "#01365A",
      "#FFFFFF" // Default color if no match
    ],
    resultsOutline: [
      "interpolate",
      ["linear"],
      ["get", "status_by_code"],
      1, "#DBE666",
      2, "#92DA7C",
      5, "#399B8C",
      7, "#024D60",
      10, "#024D60"
    ],
    resultRing: [
      "interpolate",
      ["linear"],
      ["get", "status_by_code"],
      1, "#DBE666",
      2, "#92DA7C",
      5, "#399B8C",
      7, "#024D60",
      10, "#024D60"
    ]
  };