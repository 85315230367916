/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  IconButton,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  Typography,
  Divider,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircle from '@mui/icons-material/CheckCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useTheme, styled } from '@mui/material/styles';
import { ButtonGroup, Tooltip } from '@mui/material';
import { fetchProjects, deleteProject } from '../api/project';
import io from 'socket.io-client';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setAllProjectIds, setCurrentProjectId } from '../redux/projectSlice';

const StyledBox = styled(({ collapsed, ...otherProps }) => <Box {...otherProps} />)(({ theme, collapsed }) => ({
  flex: 1,
  display: 'flex',
  padding: collapsed ? '0' : '0 5px',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  overflow: 'hidden',
  width: collapsed ? '50px' : '100%', // Adjust width based on collapse state
  transition: 'width 0.3s', // Smooth transition for width change
}));


const StyledActionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius,
    '& fieldset': {
      borderColor: theme.palette.grey[300],
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputAdornment-root .MuiIconButton-root': {
    color: theme.palette.primary.main,
  },
}));

const StyledList = styled(List)(({ theme }) => ({
  flex: 1,
  overflowY: 'auto',
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  '&::-webkit-scrollbar': {
    width: '2px',
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'transparent',
    borderRadius: '4px',
  },
  '&:hover::-webkit-scrollbar': {
    backgroundColor: theme.palette.action.disabledBackground,
  },
  '&:hover::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.light,
  },
  '& .MuiListItem-root': {
    transition: 'background-color 0.2s ease',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
}));


const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.main,
  },
  color: theme.palette.primary.main,
}));

const CompactDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    margin: 'auto',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    width: '100%', // Responsive width
    maxWidth: '400px', // Max width of the dialog
  },
}));

const CompactDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: theme.spacing(2),
  fontSize: theme.typography.h6.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.text.primary,
  textAlign: 'center', // Centers the title text
}));

const CompactDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center', // Centers the content
}));

const CompactDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(1),
  justifyContent: 'center', // Centers the actions within the dialog
  '& > *': {
    margin: theme.spacing(0.5), // Adds space between buttons
  },
}));

const CompactButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  minWidth: '90px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const CompactCancelButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  minWidth: '90px',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
}));

const LeftPanel = ({ collapsed, setCollapsed }) => {
  // const [collapsed, setCollapsed] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [projectStatus, setProjectStatus] = useState({});
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const userId = useSelector((state) => state.auth.userId);
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const currentProjectId = useSelector(state => state.project.currentProjectId);
  const isModeling = useSelector(state => state.project.isModeling);
  const currentModelingProjectId = useSelector(state => state.project.currentModelingProjectId);
  // console.log('modeling', isModeling,currentModelingProjectId)
  
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const toggleCollapse = () => setCollapsed((prev) => !prev);

  useEffect(() => {
    loadProjects();
  }, [userId, isAdmin, token]);

  useEffect(() => {
    if (isModeling && currentModelingProjectId) {
      setProjectStatus((prevStatus) => ({
        ...prevStatus,
        [currentModelingProjectId]: 'running'
      }));
    }
  }, [isModeling, currentModelingProjectId]);


  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_UE_SOCKET_PORT}`);
    socket.on('jobStatus', (data) => {
      // console.log('Received job status:', data);
      setProjectStatus((prev) => ({ ...prev, [data.projectId]: data.status }));
    });
    return () => {
      socket.off('jobStatus');
      socket.disconnect();
    };
  }, []);
  
  const loadProjects = async () => {
    setIsLoading(true);
    try {
      if(userId && token){ 
        const projects = await fetchProjects(userId,isAdmin, token);
        setItems(projects);
        const projectIds = projects.map(project => project.project_id);
        dispatch(setAllProjectIds(projectIds));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleViewProject = (projectId) => {
    navigate(`/project/${projectId}`);
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  const handleItemClick = (project) => {
    // Get the currently selected project ID from the Redux store
    // Check if the clicked project is already selected
    if (currentProjectId === project.project_id) {
      dispatch(setCurrentProjectId(null));
    } else {
      // Otherwise, select the new project
      dispatch(setCurrentProjectId(project.project_id));
    }
  };


  const handleAddButtonClick = () => {
    navigate(`/project`);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await deleteProject(selectedProjectId, userId, isAdmin, token);
      setOpenDialog(false);
      loadProjects();
    } finally {
      setIsLoading(false);
    }
  };

  const askForConfirmation = (project_id) => {
    setSelectedProjectId(project_id);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const theme = useTheme(); 

  const filteredItems = items.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const iconColor = theme.palette.mode === 'dark' ? '#ffffff' : 'inherit'; // White for dark

  return (
    <StyledBox collapsed={collapsed}>
      <StyledActionContainer>
        {!collapsed && (
          <>
            <Tooltip title={t('app.createProject')} arrow>
              <IconButton onClick={handleAddButtonClick} color="primary" aria-label="add project">
                <AddCircleIcon />
              </IconButton>
            </Tooltip>
            <StyledTextField
              label={t('app.search')}
              id={t('app.search')}
              variant="outlined"
              value={searchTerm}
              onChange={handleSearch}
              fullWidth
              InputProps={{
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <IconButton onClick={clearSearch}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
        {/* Chevron Icon for collapse/expand toggle after the search bar */}
        <Tooltip title={collapsed ? t('app.expandPanel') : t('app.collapsePanel')} arrow>
          <IconButton onClick={toggleCollapse} sx={{ color: iconColor }} aria-label="collapse panel">
            {collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </Tooltip>
      </StyledActionContainer>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : !collapsed && filteredItems.length > 0 ? (
        <StyledList>
          {filteredItems.map((item) => (
            <ListItem
              key={item.project_id}
              onClick={() => handleItemClick(item)}
              sx={{
                display: 'flex', 
                alignItems: 'center',
                borderLeft: item.project_id === currentProjectId ? '5px solid #1976d2' : 'none',
                boxShadow: item.project_id === currentProjectId ? '0px 2px 5px rgba(0, 0, 0, 0.2)' : 'none',
                bgcolor: item.project_id === currentProjectId ? 'background.paper' : 'transparent',
                '&:hover': {
                  backgroundColor: '#f5f5f5',
                  cursor: 'pointer'
                },
                transition: 'all 0.3s'
              }}
            >
              {/* Tooltip wrapping ListItemText for displaying full name on hover */}
              <Tooltip title={item.name} arrow>
                <ListItemText 
                  primary={item.name} 
                  sx={{
                    width: '100%',
                    '& .MuiTypography-root': {
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden'
                    }
                  }}
                />
              </Tooltip>

              {/* Secondary Action Buttons */}
              <ButtonGroup variant="text" color="primary" sx={{ marginLeft: 'auto', alignItems: 'center'}}>
                {(projectStatus[item.project_id] === 'running' || projectStatus[item.project_id] === 'Initiating') && (
                  <CircularProgress size={26} sx={{ marginRight: '4px', alignItems: 'center' }} />
                )}
                {projectStatus[item.project_id] === 'success' && (
                  <CheckCircle color="success" sx={{ marginRight: '4px', alignItems: 'center' }} />
                )}
                <Tooltip title={t('app.view')} arrow>
                  <StyledIconButton
                    aria-label="view"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleViewProject(item.project_id);
                    }}
                  >
                    <VisibilityIcon />
                  </StyledIconButton>
                </Tooltip>
                <Tooltip title={t('app.delete')} arrow>
                  <StyledIconButton
                    aria-label="delete"
                    onClick={(event) => {
                      event.stopPropagation();
                      askForConfirmation(item.project_id);
                    }}
                  >
                    <DeleteIcon />
                  </StyledIconButton>
                </Tooltip>
              </ButtonGroup>
            </ListItem>
          ))}
        </StyledList>

      ) : (
        !collapsed && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Typography
              variant="h6"
              sx={{
                textAlign: 'center',
                marginTop: 4,
                fontWeight: 'medium',
                color: 'text.secondary',
                letterSpacing: '0.1rem',
              }}
            >
              {t('app.noProjectsMessage')}
            </Typography>
          </Box>
        )
      )}
      <CompactDialog open={openDialog} onClose={handleDialogClose}>
        <CompactDialogTitle>{t('app.confirmDeletion')}</CompactDialogTitle>
        <Divider />
        <CompactDialogContent>
          <Typography variant="body1">
            {t('app.confirmDeletionText')}
          </Typography>
        </CompactDialogContent>
        <CompactDialogActions>
          <CompactCancelButton 
            variant="outlined" 
            onClick={handleDialogClose} 
            disabled={isLoading}
          >
            {t('app.cancel')}
          </CompactCancelButton>
          <CompactButton 
            variant="contained" 
            color="primary" 
            onClick={handleDelete} 
            autoFocus 
            disabled={isLoading}
          >
            {isLoading ? (
              <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                <CircularProgress size={20} color="inherit" />
              </Box>
            ) : (
              t('app.delete')
            )}
          </CompactButton>
        </CompactDialogActions>
      </CompactDialog>
    </StyledBox>
  );
};

export default LeftPanel;
