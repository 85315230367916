import React from 'react';
import { Box } from '@mui/material';
import MapComponent from '../components/MapComponent'; // Adjust the path as needed
import ErrorBoundary from '../components/ErrorBoundary'; // Import ErrorBoundary

const ReportPage = () => {
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <ErrorBoundary>
        <MapComponent
          center={[139.6917, 35.6895]}
          zoom={6}
          highResolution={true}
          drawTools={false} // Disable drawing tools
          overlay={true}
        />
      </ErrorBoundary>
    </Box>
  );
};

export default ReportPage;
