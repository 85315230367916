import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

const LanguageSwitcher = ({ menuItem }) => {
  const { i18n } = useTranslation();
  const theme = useTheme();

  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <Select
      value={i18n.language}
      onChange={handleChange}
      displayEmpty
      inputProps={{ 'aria-label': 'language' }}
      sx={{
        '& .MuiSelect-select': {
          color: theme.palette.text.primary, // Use theme text color
        },
        '& .MuiSelect-icon': {
          color: theme.palette.text.primary, // Set the arrow color to black
        },
      }}
    >
      <MenuItem value="en" sx={{ color: theme.palette.text.primary }}>English</MenuItem>
      <MenuItem value="ja" sx={{ color: theme.palette.text.primary }}>日本語</MenuItem>
    </Select>
  );
};

export default LanguageSwitcher;
