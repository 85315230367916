/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  TextField,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Typography,
  Select,
  InputLabel,
  OutlinedInput,
  Chip,
  Grid,
  IconButton,
  Snackbar,
  Tooltip,
  Alert,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { useDispatch } from "react-redux";
import SaveIcon from "@mui/icons-material/Save";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import DateSelector from "./DateSelector";
import { setCurrentProjectData, setIsModeling, setCurrentModelingProjectId,setShapeStatus ,setProjectStatus} from "../redux/projectSlice";
import {
  createProject,
  updateProject,
  getProjectById,
  initiateModeling,
  getResultsById,
  checkResultsById,
  getShapesByProjectId
} from "../api/project";
import {
  designedByOptions,
  statusOptions,
  modelData,
} from "../resources/projectFormOptions";
import io from "socket.io-client";

import { useSelector } from "react-redux";

const FormContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  borderRadius: theme.shape.borderRadius * 1.5,
  height: "100%",
  position: "relative",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "6px",
    backgroundColor: "transparent",
  },
  "&:hover::-webkit-scrollbar": {
    backgroundColor: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "transparent",
    borderRadius: "4px",
  },
  "&:hover::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.primary.main,
  }
}));

const MaskOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 10,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  "& .MuiOutlinedInput-root": {
    padding: theme.spacing(0.75),
    fontSize: "0.875rem",
    "& fieldset": {
      borderColor: theme.palette.grey[300],
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
  marginBottom: theme.spacing(1),
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  "& .MuiOutlinedInput-root": {
    padding: theme.spacing(0.75),
    fontSize: "0.875rem",
    "& fieldset": {
      borderColor: theme.palette.grey[300],
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
  marginBottom: theme.spacing(1),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  minWidth: "110px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0.5, 1),
  fontSize: "0.875rem",
  borderRadius: theme.shape.borderRadius,
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const BackButton = styled(Button)(({ theme }) => ({
  alignSelf: "flex-start",
  marginBottom: theme.spacing(1.5),
  color:
    theme.palette.mode === "dark"
      ? theme.palette.common.white
      : theme.palette.primary.main, // White text in dark theme
  fontSize: "0.875rem",
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  color: theme.palette.text.primary,
  fontSize: "0.875rem",
}));

const SectionBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1.5),
  boxShadow: theme.shadows[1],
}));

const ProjectForm = ({ collapsed, setCollapsed }) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language
  const { projectId } = useParams();
  const [hasShapes, setHasShapes] = useState(false);
  const navigate = useNavigate();
  const [hasCompleteScopeOfWork, setHasCompleteScopeOfWork] = useState(false);
  const userId = useSelector((state) => state.auth.userId);
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const [formValues, setFormValues] = useState({
    name: "",             
    scheduledDate: "", 
    scheduledEndDate: "",    
    plannedAmount: "",     
    designedBy: "",        
    publicPrivate: "public", // Keep this as the default
    status: "",            // Set this to empty string or a default value like 'inOperation'
    models: [1],            // Initialize as default model
  });
  
  const token = useSelector((state) => state.auth.token);
  
  const [results, setResults] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [socketData, setSocketData] = useState(null);
  const [isReceiving, setIsReceiving] = useState(false);
  const [saveAndRunButtonDisabled, setSaveAndRunButtonDisabled] =
    useState(false);
  const [resultButton, setResultButton] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const shapeStatus = useSelector((state) => state.project.shapeStatus);
  const [unauthorizedAccess, setUnauthorizedAccess] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(true);

  const userData = useSelector((state) => state.auth.userData);

  const username = userData.find(
    (attr) => attr.Name === "preferred_username"
  )?.Value;
  const email = userData.find((attr) => attr.Name === "email")?.Value;

  useEffect(() => {
    if (projectId) {
      loadProjectData(projectId);
    }
  }, [projectId, shapeStatus]);
  
  useEffect(() => {
    if (projectId) {
      loadShapes(projectId);
      dispatch(setShapeStatus(false))
    }
    
  }, [projectId, shapeStatus]);

  useEffect(() => {
    if (projectId) {
      loadResults(projectId);
    }
  }, [projectId, saveAndRunButtonDisabled]);

  const loadResults = async (id) => {
    try {
      const results = await checkResultsById(id, token);

      setResultButton(results); // Enable if results are not empty
    } catch (error) {
      console.error("Failed to fetch results:", error);
    }
  };

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_UE_SOCKET_PORT}`);
    socket.on("jobStatus", (data) => {
      const receivedProjectId = data.projectId;
      const foundProject = projectId === receivedProjectId;

      if (foundProject) {
        setSocketData(data);
        setIsReceiving(true);
        setSaveAndRunButtonDisabled(true);
        setResultButton(true);
      }

      if (data.status === "failure") {
        setIsReceiving(false);
      }

      if (data.status === "success") {
        setSaveAndRunButtonDisabled(false);
        setIsReceiving(false);
        setResultButton(false);
        dispatch(setProjectStatus('success'));
      }
    });

    return () => {
      socket.off("jobStatus");
      socket.disconnect();
    };
  }, [socketData, projectId]);

  const loadShapes = async(id) => {
    try {
       // Fetch shapes and check for "completeScopeOfWork"
       const shapes = await getShapesByProjectId(id, token);
       setHasShapes(shapes.length > 0);
       const completeScopeExists = shapes.some(
         (shape) => shape.landcover_type === "completeScopeOfWork"
       );
       setHasCompleteScopeOfWork(completeScopeExists);
    } catch (error) {
      console.log('error in fetching shapes', error)
    }
  }

  const loadProjectData = async (id) => {
    setIsLoading(true);
    try {
      const data = await getProjectById(id, userId, isAdmin, token);
      dispatch(setCurrentProjectData(data));
  
      setFormValues({
        name: data.name || "",
        scheduledDate: data.start_date || "",
        scheduledEndDate: data.end_date || "",
        plannedAmount: data.planned_amount !== null ? data.planned_amount : "",
        designedBy:
          designedByOptions.find((opt) => opt.value === data.designed_by)
            ?.value || "",
        publicPrivate: data.public ? "public" : "private",
        status: data.status || "", // Ensure this is not null or undefined
        models: data.models ? JSON.parse(data.models) : [],
      });
      
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setUnauthorizedAccess(true);
        navigate("/");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Allow only valid numbers or empty for plannedAmount
    if (name === "plannedAmount") {
      const isNumericOrEmpty = value === "" || /^[0-9]+$/.test(value);
      setIsInvalid(!isNumericOrEmpty);
    }
    
  
    // Update form values if valid input
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleKeyDown = (e) => {
    // Allow only digits and essential control keys
    if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "Tab") {
      e.preventDefault();
    }
  };

  

  const handleModelChange = (event) => {
    const value = event.target.value;
    setFormValues((prev) => ({
      ...prev,
      models: typeof value === "string" ? value.split(",") : value,
    }));

    // Disable Save and Run button if models are empty
    setSaveAndRunButtonDisabled(value.length === 0);
  };

  const handleSubmit = async () => {
    if (isInvalid) return;
    setIsLoading(true);
    try {
      const payload = {
        name: formValues.name.trim(), // Trim spaces from name
        startdate: formValues.scheduledDate
          ? formValues.scheduledDate + "T14:30:00Z"
          : null,
        enddate: formValues.scheduledEndDate
          ? formValues.scheduledEndDate + "T14:30:00Z"
          : null,
        plannedamount: parseInt(formValues.plannedAmount, 10),
        designedby: formValues.designedBy,
        status: formValues.status,
        public: formValues.publicPrivate === "public",
        models: JSON.stringify(formValues.models),
        userId,
      };
  
      if (projectId) {
        await updateProject(projectId, payload, userId, isAdmin, token);
      } else {
        const projectResponse = await createProject(payload, token);
        if (projectResponse) {
          navigate(`/project/${projectResponse}`);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setUnauthorizedAccess(true);
        navigate("/");
      }
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleRun = async () => {
    if (!hasCompleteScopeOfWork) {
      setSnackbarOpen(true);
      return;
    }
  
    try {
      if (projectId) {
        setSaveAndRunButtonDisabled(true);
        setSocketData({ status: "Initiating" });
        dispatch(setIsModeling(true));
        dispatch(setCurrentModelingProjectId(projectId));
  
        const payload = {
          name: formValues.name,
          startdate: formValues.scheduledDate
            ? formValues.scheduledDate + "T14:30:00Z"
            : null,
          enddate: formValues.scheduledEndDate
            ? formValues.scheduledEndDate + "T14:30:00Z"
            : null,
          plannedamount: parseInt(formValues.plannedAmount, 10),
          designedby: formValues.designedBy,
          status: formValues.status,
          public: formValues.publicPrivate === "public",
          models: JSON.stringify(formValues.models),
        };
  
        await updateProject(projectId, payload, userId, isAdmin, token);
        await initiateModeling(projectId, formValues.models, token);
  
        setSnackbarOpen(true);
        dispatch(setIsModeling(false));
        dispatch(setCurrentModelingProjectId(null));
      }
    } catch (error) {
      console.error("Error running models:", error);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCloseUnauthorizedSnackbar = () => {
    setUnauthorizedAccess(false);
  };
  

  return (
    <FormContainer>
      <Tooltip title={t('app.collapsePanel')} arrow>
        <IconButton
          onClick={() => setCollapsed(true)}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            color: "inherit",
          }}
        >
          <ChevronLeft />
        </IconButton>
      </Tooltip>
      {isLoading && (
        <MaskOverlay>
          <CircularProgress />
        </MaskOverlay>
      )}

      <BackButton startIcon={<ArrowBackIcon />} onClick={() => navigate("/")}>
        {t("app.back")}
      </BackButton>

      <StyledTextField
        label={t("app.name")}
        id={t("app.name")}
        variant="outlined"
        fullWidth
        name="name"
        value={formValues.name}
        onChange={handleInputChange}
        required
      />
     <SectionBox>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <DateSelector
                            label={t("app.scheduledConstructionPeriod")}
                            id={t("app.scheduledConstructionPeriod")}
                            value={formValues.scheduledDate}
                            name="scheduledDate"
                            handleChange={handleInputChange}
                            maxDate={formValues.scheduledEndDate}
                        />
                        <Typography variant="body2" color="textSecondary">
                            {t("app.startdate")}: {formValues.scheduledDate || `${t("app.unknown")}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DateSelector
                            label={t("app.enddate")}
                            id={t("app.enddate")}
                            value={formValues.scheduledEndDate}
                            name="scheduledEndDate"
                            handleChange={handleInputChange}
                            minDate={formValues.scheduledDate}
                            disabled={formValues.scheduledDate === ""}
                        />
                        <Typography variant="body2" color="textSecondary">
                            {t("app.enddate")}: {formValues.scheduledEndDate ||  `${t("app.unknown")}`}
                        </Typography>
                    </Grid>
                </Grid>
            </SectionBox>

      <SectionBox>
        <Grid container spacing={2}>
          {/* Section with Planned Amount and Designed By fields stacked vertically */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              {" "}
              {/* Minimal gap between the fields */}
              <Grid item xs={12}>
                <StyledTextField
                  label={t("app.plannedAmount")}
                  id={t("app.plannedAmount")}
                  type="number"
                  variant="outlined"
                  fullWidth
                  name="plannedAmount"
                  value={formValues.plannedAmount}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown} 
                  InputProps={{
                    startAdornment: (
                      <AccountBalanceIcon
                        sx={{ marginRight: 1, color: "action.active" }}
                      />
                    ),
                  }}
                  error={isInvalid}
  helperText={
    isInvalid
      ? `${t('app.decimalMessage')}`
      : ""
  }
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  label={t("app.designedBy")}
              
                  select
                  variant="outlined"
                  fullWidth
                  id={t("app.designedBy")}
                  name="designedBy"
                  value={formValues.designedBy}
                  onChange={handleInputChange}
                >
                  {designedByOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {currentLanguage === 'ja' ? option.title : option.label}
                    </MenuItem>
                  ))}
                </StyledTextField>
              </Grid>
            </Grid>
          </Grid>

          {/* Section with Public/Private radio buttons */}
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset" fullWidth>
              <StyledFormLabel component="legend">
                {t("app.public")}/ {t("app.private")}
              </StyledFormLabel>
              <RadioGroup
                row
                name="publicPrivate"
                value={formValues.publicPrivate}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value="public"
                  control={<Radio />}
                  label={t("app.public")}
                  id={t("app.public")}
                />
                <FormControlLabel
                  value="private"
                  control={<Radio />}
                  label={t("app.private")}
                  id={t("app.private")}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </SectionBox>

      <StyledTextField
        label={t("app.status")}
        id={t("app.status")}
        select
        variant="outlined"
        fullWidth
        name="status"
        value={formValues.status || ''}
        onChange={handleInputChange}
      >
        {statusOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
             {currentLanguage === 'ja' ? option.title : option.label}
          </MenuItem>
        ))}
      </StyledTextField>

      {/* <FormControl fullWidth>
        <InputLabel id="models-label">{t('app.models')}</InputLabel>
        <StyledSelect
          labelId="models-label"
          multiple
          value={formValues.models}
          onChange={handleModelChange}
          input={<OutlinedInput id="select-multiple-chip" label="Models" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={modelData.find(model => model.model_id === value).title} />
              ))}
            </Box>
          )}
        >
          {modelData.map((model) => (
            <MenuItem key={model.model_id} value={model.model_id}>
              {model.title}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl> */}

      {socketData && showSuccessMessage && (
        <Box
          sx={{
            mt: 2,
            p: 2,
            borderRadius: 2,
            boxShadow: 1,
            backgroundColor: "background.paper",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {socketData.status === "running" && (
              <>
                <CircularProgress size={24} sx={{ color: "primary.main" }} />
                <Typography
                  sx={{
                    color: "text.secondary",
                    fontStyle: "italic",
                    fontWeight: 500,
                  }}
                >
                  {t("app.modelingInProgress")}
                </Typography>
              </>
            )}
            {socketData.status === "Initiating" && (
              <>
                <CircularProgress size={24} sx={{ color: "primary.main" }} />
                <Typography
                  sx={{
                    color: "text.secondary",
                    fontStyle: "italic",
                    fontWeight: 500,
                  }}
                >
                  Modeling Initialized
                </Typography>
              </>
            )}
            {socketData.status === "failure" && (
              <>
                <ErrorIcon sx={{ color: "error.main" }} />
                <Typography sx={{ fontWeight: 500 }}>{`${t(
                  "app.failureCount"
                )}: ${socketData.failureCnt}`}</Typography>
              </>
            )}
            {socketData.status === "success" && (
              <>
                <CheckCircleIcon sx={{ color: "success.main" }} />
                <Typography sx={{ fontWeight: 500 }}>
                  {t("app.modelingCompleted")}
                </Typography>
              </>
            )}
          </Box>
          <IconButton size="small" onClick={() => setShowSuccessMessage(false)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      )}

      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <StyledButton
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!formValues.name.trim() || saveAndRunButtonDisabled || isInvalid}
        >
          <SaveIcon sx={{ marginRight: 1 }} />
          {t("app.save")}
        </StyledButton>

        <StyledButton
          variant="contained"
          color="primary"
          onClick={handleRun}
          disabled={!formValues.name.trim() || saveAndRunButtonDisabled || !hasShapes}
        >
          <PlayArrowIcon sx={{ marginRight: 1 }} />
          {t("app.saveAndRun")}
        </StyledButton>

        <StyledButton
          onClick={() => navigate(`/projectreport/${projectId}`)}
          variant="contained"
          color="primary"
          disabled={!projectId || !resultButton || saveAndRunButtonDisabled}
        >
          <AssessmentIcon sx={{ marginRight: 1 }} />
          {t("app.report")}
        </StyledButton>
      </Box>

      {/* Snackbar for toast notifications */}
      <Snackbar
  open={snackbarOpen}
  autoHideDuration={3000}
  onClose={handleCloseSnackbar}
  anchorOrigin={{ vertical: "top", horizontal: "center" }}
>
  <Alert
    onClose={handleCloseSnackbar}
    severity={hasCompleteScopeOfWork ? "success" : "warning"}
    sx={{
      width: "100%",
      backgroundColor: hasCompleteScopeOfWork
        ? "primary.main"
        : "warning.main",
      color: hasCompleteScopeOfWork
        ? "primary.contrastText"
        : "warning.contrastText",
      "& .MuiAlert-icon": {
        color: hasCompleteScopeOfWork
          ? "primary.contrastText"
          : "warning.contrastText",
      },
    }}
  >
    {hasCompleteScopeOfWork
      ? t("app.saveAndRunSuccess")
      : t("app.warningMessage")}
  </Alert>
</Snackbar>

      <Snackbar
        open={unauthorizedAccess}
        autoHideDuration={3000}
        onClose={handleCloseUnauthorizedSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity="error"
          onClose={handleCloseUnauthorizedSnackbar}
          sx={{ width: "100%" }}
        >
          {t("app.unauthorizedAccess")}
        </Alert>
      </Snackbar>
    </FormContainer>
  );
};

export default ProjectForm;
